import * as React from 'react';
import TypedJS from 'typed.js';

const Typed = ({ strings = [], loop = false, children }) => {
  const el = React.useRef(null);

  const onFadedOut = React.useCallback(() => {
    el.current.innerHTML = '';

    new TypedJS(el.current, {
      smartBackspace: true,
      loop,
      typeSpeed: 75,
      backSpeed: 50,
      strings,
    });

    el.current.removeEventListener('transitionend', onFadedOut);
    el.current.classList.remove('fadeable__fade-out');
  }, [strings, loop]);

  React.useEffect(() => {
    el.current.addEventListener('transitionend', onFadedOut);
    el.current.classList.add('fadeable__fade-out');
  }, [onFadedOut]);

  return (
    <span className="fadeable" ref={el}>
      {children}
    </span>
  );
};

export default Typed;
