import * as React from 'react';
import { Helmet } from 'react-helmet';
import Typed from '../components/typed';
import image from '../images/background.svg';
import facebook from '../images/facebook.png';
import twitter from '../images/twitter.png';
import instagram from '../images/instagram.png';
import linkedin from '../images/linkedin.png';
import * as style from './index.module.css';
import './global.css';

const platforms = [
  'Medium',
  'Facebook',
  'Twitter',
  'Mailchimp',
  'LinkedIn',
  'WordPress',
].map((x) => `<strong>${x}</strong>.`);

const socialLinks = [
  [facebook, 'Facebook', 'https://facebook.com/relaeio'],
  [twitter, 'Twitter', 'https://twitter.com/relaeio'],
  [instagram, 'Instagram', 'https://instagram.com/relaeio'],
  [linkedin, 'LinkedIn', 'https://linkedin.com/company/relaeio'],
];

const DESCRIPTION =
  'Author your content once, and adapt it for and publish it to Medium, Facebook, Twitter, Mailchimp, WordPress, and many more.';

const IndexPage = () => (
  <>
    <Helmet>
      <html lang="en" />
      <title>Relae: Write once. Repurpose everywhere.</title>
      <meta name="description" content={DESCRIPTION} />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="icon" sizes="16x16 32x32" href="/favicon.ico" />
      <link rel="icon" sizes="64x64" href="/favicon-64.png" />
      <link rel="icon" sizes="72x72" href="/favicon-72.png" />
      <link rel="icon" sizes="196x196" href="/favicon-196.png" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>

    <main className={style.main}>
      <h1 className={style.accessibilityTitle}>Relae</h1>
      <section className={style.content}>
        <div className={style.text}>
          <p className={style.strapline}>
            Write <strong>once</strong>. Repurpose <strong>everywhere</strong>.
          </p>
          <p className={style.platforms} aria-label={DESCRIPTION}>
            Author your content once, and adapt it for and publish it to{' '}
            {
              <Typed loop strings={platforms}>
                <strong>Medium</strong>, <strong>Facebook</strong>,{' '}
                <strong>Twitter</strong>, <strong>Mailchimp</strong>,{' '}
                <strong>WordPress</strong>, and many more.
              </Typed>
            }
          </p>
        </div>
        <img
          className={style.image}
          alt="The Relae logo with Facebook, Medium, and Wordpress as example integrations"
          src={image}
        />
      </section>
      <footer className={style.footer}>
        <div className={style.footerText}>
          Coming soon!
          <p className={style.legal}>
            © Relae Ltd. {new Date().getFullYear()}. The WordPress, Medium, and
            Facebook logos are property of the WordPress Foundation, A Medium
            Corporation, and Facebook, Inc. respectively.
          </p>
        </div>
        <div className={style.socialLinks}>
          {socialLinks.map(([src, alt, href]) => (
            <a key={href} href={href}>
              <img
                className={style.socialIcon}
                src={src}
                alt={`Relae on ${alt}`}
              />
            </a>
          ))}
        </div>
      </footer>
    </main>
  </>
);

export default IndexPage;
