// extracted by mini-css-extract-plugin
export const main = "index-module--main--X-C1v";
export const content = "index-module--content--2SsJM";
export const image = "index-module--image--i2xlR";
export const text = "index-module--text--1qnDP";
export const accessibilityTitle = "index-module--accessibilityTitle--3eOWc";
export const strapline = "index-module--strapline--30JN8";
export const platforms = "index-module--platforms--3uPx7";
export const footer = "index-module--footer--1LVh_";
export const footerText = "index-module--footerText--2gZvk";
export const legal = "index-module--legal--1RDnl";
export const socialLinks = "index-module--socialLinks--GaynU";
export const socialIcon = "index-module--socialIcon--1Rkyp";